import React from 'react';
import classnames from 'classnames';
import { useTheme } from '@web-apps/theming';
import classes from './Layout.scss';
import ClinqLogo from '../../media/images/clinq_logo.svg';

type Props = {
	children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
	const { isDark } = useTheme();

	return (
		<div className={classes.wrapper}>
			<img
				className={classnames(classes.sipgateLogo, isDark && classes.isDark)}
				src={ClinqLogo}
				alt="clinq logo"
				aria-hidden="true"
			/>
			<main className={classes.content}>{children}</main>
		</div>
	);
};

export default Layout;
