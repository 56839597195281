import React from 'react';
import { useTheme } from '@web-apps/theming';
import classnames from 'classnames';
import classes from './EntryPage.scss';
import ClinqLogo from '../../../media/images/clinq_logo.svg';

interface Props {
	href: string;
}

const BrandLogo = ({ href }: Props) => {
	const { isDark } = useTheme();

	return (
		<a
			href={href}
			className={classnames(window.top !== window.self ? classes.hide : classes.sipgateLogo, {
				[classes.isDark]: isDark,
			})}
		>
			<img src={ClinqLogo} alt="clinq logo" aria-hidden="true" />
		</a>
	);
};

export default BrandLogo;
